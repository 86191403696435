import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Login from "./component/Login.js";
import Dashboard from "./component/Dashboard.js";
import Navbar from "./component/Navbar.js";
import Admin from "./component/Admin.js";
import { ToastContainer, toast } from "react-toastify";
import Course from "./component/Course.js";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const usertype = localStorage.getItem("usertype");

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isUserLoggedIn");
    if (loggedInStatus) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isUserLoggedIn", true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isUserLoggedIn");
    localStorage.removeItem("jwt");
  };

  return (
    <Router>
      {isLoggedIn && <Navbar onLogout={handleLogout} />}{" "}
      {/* A Navbar feltételes megjelenítése */}
      <div className="app-layout">
        {/* A Sidebar csak bejelentkezve jelenik meg */}
        <div className="main-content">
          <Routes>
            <Route
              path="/login"
              element={
                !isLoggedIn ? (
                  <Login onLogin={handleLogin} />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/dashboard"
              element={
                isLoggedIn ? (
                  <Dashboard onLogout={handleLogout} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/admin"
              element={
                isLoggedIn && usertype === "admin" ? (
                  <Admin />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/course"
              element={
                isLoggedIn ? (
                  <Course onLogout={handleLogout} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="*"
              element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} />}
            />
          </Routes>
        </div>
      </div>
      <div>
        <ToastContainer autoClose={3000} />
      </div>
    </Router>
  );
}

export default App;
