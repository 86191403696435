import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BigTest.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function BigTest({ show, onClose, tests = [], courses = [] }) {
  const [testsByCourse, setTestsByCourse] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [countdown, setCountdown] = useState(300); // 5 perc visszaszámláló
  const userid = localStorage.getItem("id");
  const actualmonth = localStorage.getItem("actualmonth");

  useEffect(() => {
    if (show && courses.length > 0 && tests.length > 0) {
      const groupedTests = courses
        .map((course) => {
          const relatedTests = tests.filter(
            (test) => test.course_id === course.id
          );
          if (relatedTests.length > 0) {
            const randomTest =
              relatedTests[Math.floor(Math.random() * relatedTests.length)];
            return { course, test: randomTest };
          }
          return null;
        })
        .filter(Boolean);

      setTestsByCourse(groupedTests);
      setSelectedAnswers({});
      setScore(0);
      setCountdown(300);
    }
  }, [show, courses, tests]);

  useEffect(() => {
    if (show && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (countdown === 0) {
      handleSubmit();
    }
  }, [show, countdown]);

  const handleAnswerChange = (courseId, index) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [courseId]: prev[courseId]?.includes(index)
        ? prev[courseId].filter((i) => i !== index)
        : [...(prev[courseId] || []), index],
    }));
  };

  const handleSubmit = async () => {
    const testResults = testsByCourse.map(({ course, test }) => {
      const maxScore = JSON.parse(test.answers).filter(
        (a) => a.isCorrect
      ).length;
      const score =
        selectedAnswers[course.id]?.reduce((total, idx) => {
          const answers = JSON.parse(test.answers);
          return answers[idx]?.isCorrect ? total + 1 : total;
        }, 0) || 0;

      return {
        course_id: course.id,
        score: score,
        max_score: maxScore,
        passed: score === maxScore,
      };
    });

    const allPassed = testResults.every((result) => result.passed);

    const summaryRecord = {
      user_id: userid,
      actual_month: actualmonth,
      passed_all_tests: allPassed,
      failed_course_ids: testResults
        .filter((result) => !result.passed)
        .map((result) => result.course_id),
    };

    try {
      const response = await axios.post(
        "https://university.slimstore.hu/api/save_bigtest.php",
        summaryRecord,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Az eredmény sikeresen mentve!", { theme: "colored" });
        onClose(true);
      } else {
        toast.error("Hiba történt az eredmény mentésekor!", {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("Hiba történt a mentés során!");
      console.error(error);
    }
  };

  const handleExit = async () => {
    const failedResults = testsByCourse.map(({ course }) => ({
      course_id: course.id,
      score: 0,
      max_score: JSON.parse(course.answers || "[]").length,
      passed: false,
    }));

    const summaryRecord = {
      user_id: userid,
      actual_month: actualmonth,
      passed_all_tests: false,
      failed_course_ids: failedResults.map((result) => result.course_id),
    };

    try {
      const response = await axios.post(
        "https://university.slimstore.hu/api/save_bigtest.php",
        summaryRecord,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        toast.info("Sikertelen vizsga mentve.", { theme: "colored" });
        onClose(false);
      } else {
        toast.error("Hiba történt a mentés során!", { theme: "colored" });
      }
    } catch (error) {
      toast.error("Hiba történt az eredmény mentés során!");
      console.error(error);
    }
  };

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <div className="modal-header">
        <button className="close-btn" onClick={handleExit}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
        <div className="countdown-timer">{countdown}s</div>
        <h2>Teszt</h2>
        <div className="test-container">
          {testsByCourse.map(({ course, test }) => {
            const answers = JSON.parse(test.answers);
            return (
              <div key={course.id} className="test-section">
                <h3>{course.coursename}</h3>
                <p>{test.question}</p>
                <ul>
                  {answers.map((answer, idx) => (
                    <li key={idx}>
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            selectedAnswers[course.id]?.includes(idx) || false
                          }
                          onChange={() => handleAnswerChange(course.id, idx)}
                        />
                        {answer.text}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
        <button className="saveBig-btn" onClick={handleSubmit}>
          Teszt beküldése
        </button>
      </div>
    </div>
  );
}

export default BigTest;