import React, { useState, useEffect } from "react";
import "./AdminCourse.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdminCourse() {
  const [formData, setFormData] = useState({
    coursename: "",
    description: "",
    category: "Értékesítési alapok",
    file: null,
  });
  const customId = "custom-id-yes";
  const [courses, setCourses] = useState([]);
  const [coursewereadded,setCoursewereadded]=useState(0);

  useEffect(() => {
    fetch("https://university.slimstore.hu/api/get_courses.php")
      .then((response) => response.json())
      .then((data) => {
        // Ellenőrizzük, hogy milyen adatokat kapunk vissza

        if (data.error) {
          toast.error("Hiba történt a kurzusok lekérésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } else {
          // Ha az adatok jók, rendezzük őket kategória szerint
          const sortedCourses = data.sort((a, b) => {
            const categoryA = parseInt(a.category.split(".")[0]);
            const categoryB = parseInt(b.category.split(".")[0]);
            return categoryA - categoryB;
          });
          setCourses(sortedCourses);
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a kurzusok lekérése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  }, [coursewereadded]);

  const getCoursesByCategory = (category) => {
    return courses.filter((course) => course.category === category);
  };

  const handleOrderChange = (e, category, courseIndex) => {
    const newOrder = parseInt(e.target.value, 10);
    const coursesByCategory = getCoursesByCategory(category);
    const newCourses = [...courses];

    const categoryIndexes = coursesByCategory.map((course) =>
      courses.indexOf(course)
    );

    const [movedCourse] = coursesByCategory.splice(
      courseIndex - categoryIndexes[0],
      1
    );
    coursesByCategory.splice(newOrder - 1, 0, movedCourse);

    categoryIndexes.forEach((index, i) => {
      newCourses[index] = coursesByCategory[i];
    });

    setCourses(newCourses);
  };

  const handleDelete = (id, index) => {
    toast.error(
      ({ closeToast }) => (
        <div>
          <p>Biztosan törölni szeretnéd ezt a kurzust?</p>
          <button
            onClick={() => {
              performDelete(id, index);
              closeToast();
            }}
          >
            Igen
          </button>
          <button onClick={closeToast}>Nem</button>
        </div>
      ),
      {
        autoClose: false,
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      }
    );
  };

  const performDelete = (id, index) => {
    fetch("https://university.slimstore.hu/api/delete_course.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          toast.success("Kurzus sikeresen törölve!");
          const newCourses = courses.filter((_, i) => i !== index);
          setCourses(newCourses);
        } else {
          toast.error("Hiba történt a kurzus törlése során!");
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a törlés.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  };

  const handleSaveOrder = () => {
    fetch("https://university.slimstore.hu/api/save_sortedcourse.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(courses),
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (typeof data === "object" && data.success) {
          toast.success("Sorrend sikeresen mentve!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } else {
          toast.error("Hiba történt a sorrend mentése során! " + data, {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error,courses);
        toast.error("Szerverhiba! Nem sikerült a sorrend mentése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  };

  // Új kurzus hozzáadása
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("coursename", formData.coursename);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("category", formData.category);
    if (formData.file) {
      formDataToSend.append("file", formData.file);
    }

    fetch("https://university.slimstore.hu/api/save_course.php", {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          toast.success("Kurzus sikeresen hozzáadva!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
         // setCourses([...courses, { ...formData, fileUrl: data.fileUrl }]); 
         setCoursewereadded(+1);
          setFormData({
            coursename: "",
            description: "",
            category: "Értékesítési alapok",
            file: null,
          });
        } else if (data.error) {
          toast.error("Hiba történt a kurzus mentése során!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a mentés.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  };

  return (
    <div className="admin-course-container">
      <ToastContainer />

      {/* Kurzus hozzáadása form */}
      <div className="course-form">
        <h2>Kurzus hozzáadása</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Kurzus neve:</label>
            <input
              type="text"
              name="coursename"
              value={formData.coursename}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
  <label>Leírás:</label>
  <textarea
    name="description"
    value={formData.description}
    onChange={handleChange}
    rows="5" // A textarea magasságát állítja be
    cols="40" // A textarea szélességét állítja be
    placeholder="Írd ide a leírást..."
    required
  />
</div>
          <div className="form-group">
            <label>Kategória:</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
          

              <option value="Értékesítési alapok">Értékesítési alapok</option>
              <option value="Termékismeret">Termékismeret</option>
              <option value="Eladási folyamatok">Eladási folyamatok</option>
              <option value="Adminisztráció">Adminisztráció</option>
              <option value="Motiváció">Motiváció</option>
            </select>
          </div>
          <div className="form-group">
            <label>Fájl feltöltése ( csak .pdf kiterjesztés):</label>
            <input
              type="file"
              accept=".pdf"
              hint=" csak .pdf formátumot tölthetsz fel"
              onChange={handleFileChange}
              required
            />
          </div>
          <button type="submit" className="submit-btn">
            Hozzáad
          </button>
        </form>
      </div>

      {/* Jelenlegi kurzusok */}
      <div className="course-table">
        <h2>Jelenlegi kurzusok</h2>
        <table>
          <thead>
            <tr>
              <th>Kurzus neve</th>
              <th>Leírás</th>
              <th>Kategória</th>
              <th>Fájl</th>
              <th>Műveletek</th>
              <th>Sorrend</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course, index) => (
              <tr key={index}>
                <td data-label="Kurzus neve">{course.coursename || "N/A"}</td>
                <td data-label="Leírás">{course.description || "N/A"}</td>
                <td data-label="Kategória">{course.category || "N/A"}</td>
                <td data-label="Fájl">
                  {course.file_url && (
                    <a 
                    href={`https://university.slimstore.hu/uploads/${course.file_url}`}
                     download>
                      <FontAwesomeIcon icon={faFilePdf} size="lg" />
                    </a>
                  )}
                </td>
                <td data-label="Műveletek">
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(course.id, index)}
                  >
                    Törlés
                  </button>
                </td>
                <td data-label="Sorrend">
                  <select
                    value={
                      getCoursesByCategory(course.category).indexOf(course) + 1
                    }
                    onChange={(e) =>
                      handleOrderChange(e, course.category, index)
                    }
                  >
                    {getCoursesByCategory(course.category).map((_, i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="save-order-btn" onClick={handleSaveOrder}>
          Sorrend mentése
        </button>
      </div>
    </div>
  );
}

export default AdminCourse;
