import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TesztPopup.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TesztPopup({ show, onClose, tests = [], actualCourse }) {
  const [testsById, setTestsById] = useState([]);
  const [countdown, setCountdown] = useState(30);
  const [randomTest, setRandomTest] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [score, setScore] = useState(null);

  const customId = "custom-id-yes";
  const userid = localStorage.getItem("id");

  useEffect(() => {
    if (actualCourse) {
      const filteredTests = tests.filter(
        (test) => test.course_id === actualCourse.id
      );
      setTestsById(filteredTests);
      if (filteredTests.length > 0) {
        const randomQuestion =
          filteredTests[Math.floor(Math.random() * filteredTests.length)];
        setRandomTest(randomQuestion);
      }
    } else {
      setTestsById([]);
      setRandomTest(null);
    }
  }, [tests, actualCourse]);

  useEffect(() => {
    if (show) {
      setCountdown(30);
      setScore(null); // Reseteljük a pontszámot minden új megnyitásnál
      setSelectedAnswers([]); // Reseteljük a választásokat is
    }
  }, [show]);

  useEffect(() => {
    if (show && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [show, countdown]);

  useEffect(() => {
    if (countdown === 0) {
      onClose(score > 0);
    }
  }, [countdown, onClose]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      // onClose(score > 0);
    }
  };

  const handleAnswerChange = (index) => {
    setSelectedAnswers((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    );
  };

  const evaluateAnswers = () => {
    if (randomTest && randomTest.answers) {
      const answers = JSON.parse(randomTest.answers);
      let correct = true; // Alapértelmezetten helyes

      answers.forEach((answer, index) => {
        if (
          (answer.isCorrect && !selectedAnswers.includes(index)) || // Ha helyes, de nincs kijelölve
          (!answer.isCorrect && selectedAnswers.includes(index)) // Ha helytelen, de ki van jelölve
        ) {
          correct = false; // Sikertelen a teszt
        }
      });

      setScore(correct ? answers.filter((a) => a.isCorrect).length : 0); // Helyes válaszok számát adjuk meg pontszámként, de csak ha nincs helytelen választás
    }
  };

  useEffect(() => {
    if (score !== null) {
      handleSubmit();
    }
  }, [score]);

  const handleSubmit = async () => {
    if (actualCourse && randomTest) {
      const testResultData = {
        user_id: userid,
        test_id: randomTest.id,
        course_id: actualCourse.id,
        score: score || 0,
        max_score: JSON.parse(randomTest.answers).filter((a) => a.isCorrect)
          .length,
        passed: score > 0, // Csak akkor sikeres, ha legalább 1 pontot szerzett, és nem jelölt be helytelen választ
        next_available_course: actualCourse.course_order + 1,
      };

      try {
        const response = await axios.post(
          "https://university.slimstore.hu/api/save_testresult.php",
          testResultData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (score > 0) {
          toast.success("A teszt sikerült! Irány a következő topic-ra", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
          onClose(score > 0);
        } else {
          toast.error(
            "Sajnos ez most nem sikerül. Pár perc múlva próbáld meg újra",
            {
              position: "bottom-center",
              toastId: customId,
              theme: "colored",
            }
          );

          onClose(score > 0);
        }
      } catch (error) {
        alert("Hiba történt a mentés során! " + error.message);
        console.log("Error:", error);
      }
    }
  };

  if (!show) {
    return null;
  }

  const answers =
    randomTest && randomTest.answers ? JSON.parse(randomTest.answers) : [];

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <div className="countdown-timer">{countdown}s</div>
        <h2>Teszt</h2>
        <p>
          <strong>A teszt témája a következő:</strong>{" "}
          {actualCourse?.coursename || "Nincs kiválasztva kurzus"}
        </p>

        {randomTest ? (
          <>
            <h1>{randomTest.question}</h1>
            <ul>
              {answers.map((answer, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedAnswers.includes(index)}
                      onChange={() => handleAnswerChange(index)}
                    />
                    {answer.text}
                  </label>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>Nincs elérhető teszt ehhez a kurzushoz.</p>
        )}

        {score !== null && (
          <p>
            Pontszám: {score} / {answers.filter((a) => a.isCorrect).length}
          </p>
        )}

        <div className="footer">
          <button
            className="sendBig-btn"
            onClick={evaluateAnswers} // Csak az evaluateAnswers hívása
          >
            Válasz beküldése
          </button>
          <button className="closeBig-btn" onClick={() => handleSubmit()}>
            Bezárás
          </button>
        </div>
      </div>
    </div>
  );
}

export default TesztPopup;
