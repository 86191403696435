import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import Cap from "../cap.png";

const Navbar = ({ onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const username = localStorage.getItem("username");
  const isAdmin = localStorage.getItem("usertype") === "admin";

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleClickMenu = (menuType) => {
    setIsMenuOpen(false);
    switch (menuType) {
      case "Admin":
        return () => navigate("/admin");
      case "Dashboard":
        return () => navigate("/dashboard");
      case "Course":
        return () => navigate("/course");
      case "LogoutUser":
        onLogout();
        return () => navigate("/login");
      default:
        return () => {};
    }
  };

  // Bezárja a menüt, ha bárhová máshová kattintasz
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-menu') && !event.target.closest('.menu-icon')) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <img src={Cap} alt="capicon" style={{height: "30px", paddingLeft: "20px", filter: "invert(1)"}}/>
      <div className="logo"></div> {/* Logo balra kerül */}


      <div className="menu-icon" onClick={toggleMenu}>
      <div className="navbar-username">
    {username ? <span className='welcomeText'>Üdv, {username}!</span> : <span className='welcomeText' onClick={() => handleClickMenu("Logout")()}>Bejelentkezés</span>}
  </div>
       <div style={{color: "white"}}> &#9776;</div>
      </div>
      {isMenuOpen && (
        <ul className="dropdown-menu">
          {isAdmin && <li onClick={() => handleClickMenu("Admin")()}>Admin</li>}
          <li onClick={() => handleClickMenu("Dashboard")()}>Dashboard</li>
          <li onClick={() => handleClickMenu("Course")()}>Kurzusok</li>
          <li onClick={() => handleClickMenu("LogoutUser")()}>Kijelentkezés</li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;