import React, { useState, useRef, useEffect } from "react";
import "./Createuser.css";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faGear } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

function Createuser() {
  const [userData, setUserData] = useState({
    username: "",
    fullname: "",
    email: "",
    password: "",
    usertype: "promoter",
    isslimfighter:0,
  });
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isUsernameDisabled, setIsUsernameDisabled] = useState(false);
  const usersPerPage = 5;
  const [mode, setMode] = useState("insert");
  const sectionRef = useRef(null);
  const customId = "custom-id-yes";

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value, // Checkbox esetén boolean -> 1 vagy 0
    }));
  };

  // Validációs függvény
  const validateForm = () => {
    const { username, fullname, email, password, usertype } = userData;

    if (!username.trim() || !fullname.trim() || !email.trim()) {
      toast.error("Minden mező kitöltése kötelező!", {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Egyszerű email validáció
    if (!emailPattern.test(email)) {
      toast.error("Érvénytelen email formátum!", {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
      return false;
    }

    if (mode === "insert" && (!password || password.trim().length < 6)) {
      toast.error("A jelszónak legalább 6 karakter hosszúnak kell lennie!", {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return; // Ha a validáció nem sikerül, ne folytassa
    }

    if (mode === "insert") {
      const newUser = {
        ...userData,
        id: Date.now(),
      };
  
      setUsers((prevUsers) => [...prevUsers, newUser]);
      setUserData({
        username: "",
        fullname: "",
        email: "",
        password: "",
        usertype: "promoter",
        isslimfighter:0,
      });
  
      setIsUsernameDisabled(false);
    }
  };

  const handleDelete = async (id) => {

    Swal.fire({
      title: 'Felhasználó törlése',
      text: 'Biztosan folytatod a user törlését?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem',
      reverseButtons: true
    }).then(async (result) => {
    
        try {
          await axios.post("https://university.slimstore.hu/api/deleteuser.php", { id });
          setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
          toast.success("Sikeres törlés", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } catch (error) {
          console.error("Hiba történt:", error);
          toast.error("Sikertelen törlés", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      
    
    });
    
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredUsers.length / usersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // scrollToSection függvény hozzáadása
  const scrollToSection = (id) => {
    setMode("modify");
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
    
    const user = users.find((user) => user.id === id);
    if (user) {
      setUserData(user);
      setIsUsernameDisabled(true);
    }
  };

  const saveDataToServer = async () => {
    if (!validateForm()) {
      return; // Ha a validáció nem sikerül, ne folytassa
    }

    const modifiedData = { ...userData };
  
    if (mode === "modify" && (!userData.password || !userData.password.trim())) {
      delete modifiedData.password;
    }

    if (mode === "modify") {
      try {
        const response = await axios.post(
          "https://university.slimstore.hu/api/updateuser.php",
          modifiedData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        toast.success("Sikeres user módosítás", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
        setUserData({
          username: "",
          fullname: "",
          email: "",
          password: "",
          usertype: "promoter",
          isslimfighter:0,
        });
      } catch (error) {
        toast.error("Hiba történt user módosítás közben", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      }
    } else {
      try {
        const response = await axios.post(
          "https://university.slimstore.hu/api/createuser.php",
          userData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.data.success) {
          toast.error("Sikertelen user mentés felhasználó már létezik", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } else {
          toast.success("Sikeres user mentés", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      } catch (error) {
        toast.error("Sikertelen user mentés", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      }
    }
  };

  const getUser = async () => {
    try {
      const response = await axios.get("https://university.slimstore.hu/api/getusers.php");
      setUsers(response.data.users);
    } catch (error) {
      console.error("Hiba történt:", error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="user-management">
      <form onSubmit={handleSubmit} className="content">
        <div ref={sectionRef} className="form-group">
          <label>Felhasználónév:</label>
          <input
            required
            type="text"
            name="username"
            value={userData.username}
            onChange={handleChange}
            placeholder="Felhasználónév megadása"
            disabled={isUsernameDisabled}
          />
        </div>
        <div className="form-group">
          <label>Teljes Név:</label>
          <input
            required
            type="text"
            name="fullname"
            value={userData.fullname}
            onChange={handleChange}
            placeholder="Teljes Név megadása"
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            required={mode === "insert"}
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            placeholder="Email megadása"
          />
        </div>
        <div className="form-group">
          <label>Jelszó:</label>
          <input
            type="password"
            name="password"
            value={userData.password}
            onChange={handleChange}
            placeholder="Jelszó megadása"
            required={mode === "insert"}
          />
        </div>
        <div className="form-group">
          <label>Szerepkör:</label>
          <select
            required
            name="usertype"
            value={userData.usertype}
            onChange={handleChange}
            disabled={isUsernameDisabled}
          >
            <option value="promoter">Promoter</option>
            <option value="admin">Admin</option>
          </select>
        </div>
      <div className="form-group">
  <label>
    Slimmer harcos:
    <input
      type="checkbox"
      name="isslimfighter"
     // checked={userData.isslimfighter === 1}
      onChange={handleChange}
    />
  </label>
</div>
        <div className="form-group element">
          <button type="submit" className="submit-btn" onClick={saveDataToServer}>
            Hozzáad
          </button>
        </div>
      </form>

      <div className="table-container">
        <h2>Jelenlegi felhasználók</h2>
        <div className="pagination">
          <button onClick={prevPage} disabled={currentPage === 1}>
            ◄
          </button>
          <span>
            Page {currentPage} of {Math.ceil(filteredUsers.length / usersPerPage)}
          </span>
          <button
            onClick={nextPage}
            disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}
          >
            ►
          </button>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Keresés felhasználónév, teljes név vagy email alapján..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="search-icon">🔍</span>
        </div>

        <table className="data-table">
          <thead>
            <tr>
              <th>Felhasználónév</th>
              <th>Teljes Név</th>
              <th>Email</th>
              <th>Szerepkör</th>
              <th>Műveletek</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user) => (
              <tr key={user.id}>
                <td data-label="Felhasználónév">{user.username}</td>
                <td data-label="Teljes Név">{user.fullname}</td>
                <td data-label="Email">{user.email}</td>
                <td data-label="Szerepkör">{user.usertype}</td>
                <td data-label="Műveletek">
  {user.usertype !== "admin" && (
    <>
      <button
        className="delete-btn"
        onClick={() => handleDelete(user.id)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
      <button
        className="modify-btn"
        onClick={() => scrollToSection(user.id)}
      >
        <FontAwesomeIcon icon={faGear} />
      </button>
    </>
  )}
</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Createuser;
