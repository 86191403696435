import React, { useState, useEffect } from "react";
import "./Admin.css";
import logo from "../slimuniversity.png";
import axios from "axios";
import Createuser from "./Createuser";
import AdminCourse from "./AdminCourse";
import Modal from "./Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Teszt from "./Teszt";
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
  faCircleInfo,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Admin() {
  const customId = "custom-id-yes";
  const [formData, setFormData] = useState({
    videourl: "",
    watchfor: "promoter",
    title: "",
    description: "",
    maincourseid: 0,
  });

  const [gridData, setGridData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedForm, setSelectedForm] = useState("video");
  const [filter, setFilter] = useState("mind");
  const [firstfiveData, setFirstfiveData] = useState([]);
  const [stageofListedVideoNumber, setStageofListedVideoNumber] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [mainCourse, setMainCourse] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    sortData();
  }, [gridData, filter]);

  useEffect(() => {
    setFirstfiveData(filteredData.slice(0, 5));
  }, [filteredData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://university.slimstore.hu/api/getcourses.php"
      );
      if (response.data && Array.isArray(response.data)) {
        setGridData(response.data);
        setFilteredData(response.data);
      } else {
        toast.error("Nincsenek elérhető adatok.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Hiba történt az adatok lekérdezése során:", error);
      toast.error("Sikertelen az adatok lekérése " + error, {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
    }
  };

  const handleFormSelectionChange = (e) => {
    setSelectedForm(e.target.value);
  };

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);
    sortData(selectedFilter);
  };

  const sortData = (selectedFilter = filter) => {
    if (selectedFilter === "mind") {
      setFilteredData(gridData); // Összes kurzus megjelenítése
    } else {
      const filtered = gridData.filter(
        (item) => getMainCourseName(item.maincourseid) === selectedFilter
      );
      setFilteredData(filtered);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newGridData = [...gridData, formData];
    setGridData(newGridData);
    setFormData({
      videourl: "",
      watchfor: "promoter",
      title: "",
      description: "",
      maincourseid: 0,
    });
    sortData(filter);
  };

  const handleDelete = (index) => {
    const globalIndex = stageofListedVideoNumber + index;
    const newGridData = gridData.filter((_, i) => i !== globalIndex);
    setGridData(newGridData);
    sortData(filter);
    toast.warn("A törlés csak a mentés gombbal véglegesítődik", {
      position: "top-center",
      toastId: customId,
      theme: "colored",
    });
  };

  const saveDataToServer = async () => {
    try {
      const response = await axios.post(
        "https://university.slimstore.hu/api/save_data.php",
        gridData.map((item) => ({
          ...item,
          maincourseid: parseInt(item.maincourseid) || 0,
        })),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Sikeres mentés", {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
    } catch (error) {
      console.error("Hiba történt:", error);
      toast.error("Sikertelen mentés", {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
    }
  };

  const nextFive = (num) => {
    const newStage = stageofListedVideoNumber + num;
    if (newStage >= 0 && newStage < filteredData.length) {
      setStageofListedVideoNumber(newStage);
      setFirstfiveData(filteredData.slice(newStage, newStage + 5));
    }
  };

  const infobtClick = (index) => {
    const globalIndex = stageofListedVideoNumber + index;
    const video = filteredData[globalIndex];
    setSelectedVideo(video);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  useEffect(() => {
    fetch("https://university.slimstore.hu/api/get_courses.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toast.error("Hiba történt a kurzusok lekérésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } else {
          const sortedCourses = data.sort((a, b) => {
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            if (a.coursename < b.coursename) return -1;
            if (a.coursename > b.coursename) return 1;
            return 0;
          });
          setMainCourse(sortedCourses);
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a kurzusok lekérése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  }, []);

  const getMainCourseName = (maincourseid) => {
    const course = mainCourse.find(
      (course) => parseInt(course.id) === parseInt(maincourseid)
    );
    return course ? course.coursename : "Ismeretlen";
  };

  return (
    <div className="admin">
      <ToastContainer autoClose={1000} />
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div>
        <label>Admin felület - videó feltöltése és felhasználók kezelése</label>
      </div>
      <div className="form-selection">
        <label>
          <input
            type="radio"
            value="video"
            checked={selectedForm === "video"}
            onChange={handleFormSelectionChange}
          />
          Videó feltöltése
        </label>
        <label>
          <input
            type="radio"
            value="user"
            checked={selectedForm === "user"}
            onChange={handleFormSelectionChange}
          />
          Felhasználó létrehozása
        </label>
        <label>
          <input
            type="radio"
            value="course"
            checked={selectedForm === "course"}
            onChange={handleFormSelectionChange}
          />
          Kurzus létrehozása
        </label>
        <label>
          <input
            type="radio"
            value="teszt"
            checked={selectedForm === "teszt"}
            onChange={handleFormSelectionChange}
          />
          Teszt kérdések
        </label>
      </div>
      <button onClick={toggleFormVisibility} className="toggle-button">
        <FontAwesomeIcon icon={isFormVisible ? faChevronUp : faChevronDown} />
      </button>
      {selectedForm === "video" && isFormVisible && (
        <form onSubmit={handleSubmit} className="content">
          <div className="form-group">
            <label htmlFor="courseid">Válassz egy kurzust:</label>
            <select
              id="courseid"
              value={formData.maincourseid}
              required
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  maincourseid: e.target.value,
                }))
              }
            >
              <option value="">Válassz egy kurzust</option>
              {mainCourse.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.category + " - " + course.coursename}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Video URL:</label>
            <input
              required
              type="text"
              name="videourl"
              value={formData.videourl}
              onChange={handleChange}
              placeholder="Enter video URL"
            />
          </div>
          <div className="form-group">
            <label>Kik láthatják:</label>
            <select
              required
              name="watchfor"
              value={formData.watchfor}
              onChange={handleChange}
            >
              {mainCourse.map((course) => (
                <option key={course.id} value={course.coursename}>
                  {course.coursename}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group element">
            <label>Címe:</label>
            <input
              required
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter title"
            />
          </div>
          <div className="form-group element">
            <label>Egyéb leírás:</label>
            <input
              required
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter description"
            />
          </div>
          <div className="form-group element">
            <button type="submit" className="submit-btn">
              Hozzáad
            </button>
          </div>
        </form>
      )}
      {selectedForm === "video" && (
        <>
          <br />
          <div className="filter-container">
            <label htmlFor="filter-select">Szűrés:</label>
            <select
              id="filter-select"
              value={filter}
              onChange={handleFilterChange}
            >
              <option value="mind">Összes</option>
              {mainCourse.map((course) => (
                <option key={course.id} value={course.coursename}>
                  {course.coursename}
                </option>
              ))}
            </select>
          </div>
          <div
            className="arrow-buttons"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "150px",
            }}
          >
            <button onClick={() => nextFive(-5)}>
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </button>
            <button onClick={() => nextFive(5)}>
              <FontAwesomeIcon icon={faArrowRight} size="lg" />
            </button>
          </div>

          <div className="table-container">
            <h2>Jelenlegi mentett videók</h2>
            <table className="data-table">
              <thead>
                <tr>
                  <th>Video URL</th>
                  <th>Láthatóság</th>
                  <th>Cím</th>
                  <th>Leírás</th>
                  <th>Főkurzus</th>
                  <th>Műveletek</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(firstfiveData) && firstfiveData.length > 0 ? (
                  firstfiveData.map((item, index) => (
                    <tr key={index}>
                      <td data-label="Video URL">{item.videourl}</td>
                      <td data-label="Láthatóság">{item.watchfor}</td>
                      <td data-label="Cím">{item.title}</td>
                      <td data-label="Leírás">{item.description}</td>
                      <td data-label="Főkurzus">
                        {getMainCourseName(item.maincourseid)}
                      </td>
                      <td data-label="Műveletek">
                        <div className="button-container">
                          <button
                            className="delete-btn"
                            onClick={() => handleDelete(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button
                            className="info-btn"
                            onClick={() => infobtClick(index)}
                          >
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">Nincs megjeleníthető adat</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <br />
          <button className="save-btn" onClick={saveDataToServer}>
            Mentés
          </button>
        </>
      )}
      {selectedForm === "user" && <Createuser />}
      {selectedForm === "course" && <AdminCourse />}
      {selectedForm === "teszt" && <Teszt />}
      <Modal show={isModalOpen} onClose={closeModal} video={selectedVideo} />
    </div>
  );
}

export default Admin;
