import React from "react";
import logo from "../slimuniversity.png";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Helyes import
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Chart.js regisztrálása
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ onLogout }) => {
  const [courses, setCourses] = useState([]);

  const navigate = useNavigate();

  const username = localStorage.getItem("username");
  const usertype = localStorage.getItem("usertype");
  const [bigTestDownloaded , setBigTestDownloaded] = useState(false);
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(
      2,
      "0"
    )}`
  ); // Alapértelmezett az aktuális év-hónap
  const [bigtest, setBigtest] = useState({});
  const [chartData, setChartData] = useState(null);
  localStorage.setItem(
    "actualmonth",
    `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(
      2,
      "0"
    )}`
  );


  const fetchData2 = async () => {
    try {
      const response = await axios.get(
        "https://university.slimstore.hu/api/getcourses.php"
      );
      const data = Array.isArray(response.data) ? response.data : []; // Ha a válasz nem tömb, akkor üres tömböt állítunk be

      setCourses(data);
    } catch (error) {
      console.error("Hiba történt az adatok lekérdezése során:", error);
    }
  };
  useEffect(() => {
  }, [courses]);
  // Használjuk az useEffect-et, hogy a komponens betöltődésekor lekérjük az adatokat
  useEffect(() => {
    fetchData2();
  }, []);

  useEffect(() => {
    const refreshAccessToken = async () => {
      const token = localStorage.getItem("jwt");
      const refreshToken = localStorage.getItem("refreshToken");
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          // Token lejárt, megújítás szükséges
          try {
            const response = await axios.post(
              "https://university.slimstore.hu/api/refresh.php",
              {
                token: refreshToken,
              }
            );
            localStorage.setItem("jwt", response.data.accessToken);
          } catch (error) {
            onLogout();
            navigate("/login");
          }
        }
      }
    };

    const interval = setInterval(() => {
      refreshAccessToken();
    }, 5 * 60 * 1000); // 5 percenként ellenőrizzük a token érvényességét

    return () => clearInterval(interval); // Tisztítás az effectből való kilépéskor
  }, [onLogout, navigate]);

  useEffect(() => {
    const yearMonth = selectedYearMonth;
    fetch(
      `https://university.slimstore.hu/api/monthly_tests_by_user.php?yearMonth=${yearMonth}`
    )
      .then((response) => {
        if (!response.ok) {
          // Hálózati vagy szerverhiba ellenőrzése
          throw new Error("Hálózati hiba történt: " + response.statusText);
        }
        return response.json(); // JSON formátum feldolgozása
      })
      .then((data) => {
        if (data.success) {

          const labels = data.data.map(
            (item) => `${item.Felhasználónév} (${item.Kurzus})`
          );
          const datasetValues = data.data.map((item) =>
            parseInt(item["Próbálkozások száma"], 10)
          ); // Próbálkozások száma

          // Állapot frissítése
          setChartData({
            labels,
            datasets: [
              {
                label: "Próbálkozások száma",
                data: datasetValues,
                backgroundColor: "rgba(75, 192, 192, 0.6)", // Oszlop színe
                borderColor: "rgba(75, 192, 192, 1)", // Keretszín
                borderWidth: 1,
              },
            ],
          });
        } else {
          console.error(
            "Hiba az SQL lekérdezésben vagy nincs adat:",
            data.error
          );
        }
      })
      .catch((error) => {
        console.error("Hiba történt a kérés során:", error);
      });

      fetch(
        `https://university.slimstore.hu/api/get_bigtest_statistics.php?yearMonth=${yearMonth}` + "-01"
      )
        .then((response) => {
          if (!response.ok) {
            // Hálózati vagy szerverhiba ellenőrzése
            throw new Error("Hálózati hiba történt: " + response.statusText);
          }
          setBigTestDownloaded(true);
          return response.json(); // JSON formátum feldolgozása
        })
        .then((data) => {
          if (data.success) {
            setBigtest(data.data);
          } else {
            console.error(
              "Hiba az SQL lekérdezésben vagy nincs adat:",
              data.error
            );
          }
        })
        .catch((error) => {
          console.error("Hiba történt a kérés során:", error);
        });
  }, [selectedYearMonth]);

  const getYearMonths = () => {
    const currentYear = new Date().getFullYear();
    const months = [];
    for (let year = currentYear - 1; year <= currentYear; year++) {
      for (let month = 1; month <= 12; month++) {
        const formattedMonth = String(month).padStart(2, "0");
        months.push(`${year}-${formattedMonth}`);
      }
    }
    return months;
  };

  if (!chartData) {
    return <div>Töltés...</div>;
  }
  const renderUserStatsTable = () => (
    <div className="user-stats-table">
      <h3>Nagyteszt Próbálkozások és Eredmények</h3>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Név</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Próbálkozások</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Eredmény</th>
          </tr>
        </thead>
        <tbody>
          {bigtest.map((user) => (
            <tr key={user.user_id}>
              <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                {user.fullname}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                {user.total_attempts}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                {user.result_status === "Sikeres" ? "✅" : "❌"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  return (
    <div className="dashboard">
      <div className="content">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Üdv az oldalon {username}!</h2>
        
        <h3>
        {courses.length} elérhető kurzus.
        </h3>
        {usertype === "admin" ? (
        <div>
       
          <div>
<div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    textAlign: "center",
    margin: "20px auto",
  }}
>
  <select
    value={selectedYearMonth}
    onChange={(e) => setSelectedYearMonth(e.target.value)}
  >
    {getYearMonths().map((yearMonth) => (
      <option key={yearMonth} value={yearMonth}>
        {yearMonth}
      </option>
    ))}
  </select>
</div>    
        </div>

        <div className="chart-container">
        {bigTestDownloaded && renderUserStatsTable()}
    </div>
    <div className="chart-container">
      <div>
        <h2>Havi Próbálkozások Kurzusonként</h2>
        {chartData && chartData.labels.length > 0 ? (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Felhasználónév (Kurzus)</th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Próbálkozások száma</th>
              </tr>
            </thead>
            <tbody>
              {chartData.labels.map((label, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{label}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                    {chartData.datasets[0].data[index]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Nincs elérhető adat a kiválasztott hónapra.</p>
        )}
      </div>
    </div>

</div>) : null}
      </div>
    </div>
  );
};

export default Dashboard;
